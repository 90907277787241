import React from 'react'
import './ui.css'

const PrimaryButton = ({ backgroundColor, color, title, borderWidth, paddingLeft, paddingRight, id, className }) => {
    // const handleClick=()=>{
    //     window.open('https://www.dashboard.priya.ai/onboarding', '_blank')
    // }

    return (
        <div id={id} className={`primary-btn-div ${className}`} style={{ backgroundColor: backgroundColor ? backgroundColor : 'white', color: color ? color : 'black', borderColor: backgroundColor ? backgroundColor : 'white', borderWidth: borderWidth ? borderWidth : '2px', paddingLeft: paddingLeft, paddingRight: paddingRight, cursor: 'pointer'  }}>
            <a target='_blank' rel="noreferrer" href="https://apps.shopify.com/priya">
                {title}
            </a>
        </div>
    )
}

export default PrimaryButton
