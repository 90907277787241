import Footer from 'components/Footer'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import React, { useEffect } from 'react'
import { Col, Row, Button, Container } from 'reactstrap'
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from 'react-router-dom';
import './index.css'

const PPM = () => {

    const history = useHistory()

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | PPM-1</title>
                <meta name="title" content="PRIYA | PPM-1" />
                <meta name="description" content="The World's First Price Prediction Model. PPM is used by PRIYA to predict the next price with the highest likelihood of a conversion for any product or service." />
            </Helmet>
            <div className='ppm-section'>
                <div className='bg-dark'>
                    <div className="bg-banner">
                        <section className="py-0 nav-section">
                            <IndexNavbar className="fixed-top" />
                        </section><section className='my-sm-5'>
                            <Row className='align-items-center'>
                                <Col md="7">
                                    <h2 className="text-uppercase font-banner-research mb-0 ff-light text-banner mr-sm-4 mt-2">Introducing PPM</h2>
                                </Col>
                                <Col md="5">
                                    <p className="text-gray font-style ff-light text-24 mt-4 mt-md-0">The World's First Price Prediction Model. PPM is used by PRIYA to predict the next price with the highest likelihood of a conversion for any product or service.</p>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
                <div className='bg-light-gray'>
                    <section>
                        <h1 className='ff-inter-semi text-dark text-35'>Powerful Price Prediction Engine</h1>
                        <p className='ff-inter-regular text-dark text-22 mt-4 width-75'>PPM is a highly trained transformer model used to run, interpret, and predict price over time. The goal.. predict the next price that will have the highest likelihood of a conversion.</p>
                        <div className='my-5 text-center'>
                            <img src={require('../../../assets/img/PPM-1/prediction-engine.png').default} className='img-fluid width-75 d-none d-sm-inline' />
                            <img src={require('../../../assets/img/PPM-1/prediction-engine-mobile.png').default} className='img-fluid d-sm-none' />
                        </div>
                    </section>
                </div>
                <div className='bg-white'>
                    <section>
                        <h1 className='ff-inter-semi text-dark text-35 width-75'>Interpreting Macro and Micro Economic Behavior</h1>
                        <p className='ff-inter-regular text-dark text-22 mt-4 width-75'>PPM has been designed to interpret macroeconomic conditions such as competitor data, supply chain costs, economic conditions, as well as consumer behavior data and sales data to predict optimal prices.. all in real-time.</p>
                        <div className='my-5 text-center'>
                            <img src={require('../../../assets/img/PPM-1/macro.png').default} className='img-fluid width-75 d-none d-sm-inline' />
                            <img src={require('../../../assets/img/PPM-1/macro-mobile.png').default} className='img-fluid d-sm-none' />
                        </div>
                    </section>
                </div>
                <div className='bg-light-gray'>
                    <section>
                        <h1 className='ff-inter-semi text-dark text-35'>Advanced Learning on a Per-Product Basis</h1>
                        <p className='ff-inter-regular text-dark text-22 mt-4 width-75'>PRIYA runs on PPM, which enables accurate price predictions on a granular per-product level. This means prices can be optimized for every single individual product and service sold online. PPM is also trained on an industry level such as apparel, electronics, appliances, etc.</p>
                        <div className='my-5 text-center'>
                            <img src={require('../../../assets/img/PPM-1/learning-group.png').default} className='img-fluid width-75 d-none d-sm-inline' />
                            <img src={require('../../../assets/img/PPM-1/learning-group-mobile.png').default} className='img-fluid d-sm-none' />
                        </div>
                    </section>
                </div>
                <div className='bg-white'>
                    <section>
                        <p className='ff-inter-regular text-26 text-left text-dark px-lg-5 mx-lg-5'>“Based on our latest data with 60 different stores and 1000’s of various products, PRIYA can be up to 73% more accurate at setting prices compared to humans. This is absolutely astounding data. Price is everywhere, and the entire economy is affected by it, it turns out a highly trained, self-learning machine is actually more accurate than humans, and not just by a small margin, but by a large sum.”</p>
                        <p className='ff-inter-semi text-right text-dark mt-5'>- AI Researcher at Y CORP</p>
                    </section>
                </div>
                <div className='bg-dark'>
                    <section>
                        <h1 className='ff-inter-semi text-white text-35'>Product Pricing Over Time Algorithm</h1>
                        <p className='ff-inter-regular text-gray text-22 mt-4 width-75'>At Y CORP, our team of researchers developed a proprietary pricing algorithm to interpret and run the PPM model. This mathematical concept allows us to set a framework on how artificial intelligence can operate efficiently with minimal human intervention.</p>
                        <div className='my-5 text-center text-sm-left'>
                            <img src={require('../../../assets/img/PPM-1/formula.png').default} className='img-fluid d-none d-sm-inline' width={350} />
                            <img src={require('../../../assets/img/PPM-1/formula-mobile.png').default} className='img-fluid d-sm-none' width={250} />
                        </div>
                    </section>
                </div>
                <div className='bg-white'>
                    <section className='pb-4'>
                        <h1 className='ff-inter-semi text-dark text-35 width-75'>Help Improve PRIYA by Using Her</h1>
                        <p className='ff-inter-regular text-dark text-22 mt-4 width-75'>While connected to each store, PRIYA learns at an astounding rate to improve pricing accuracy
                            over time. This means better dynamic pricing, which will lead to more revenue for business owners connected to PRIYA. By using PRIYA you’ll directly participate in helping train her so all other connected businesses will benefit as well.</p>
                        <div className='mt-5 mb-sm-5 text-center'>
                            <img src={require('../../../assets/img/PPM-1/PRIYA-group.png').default} className='img-fluid width-75' />
                        </div>
                    </section>
                    <Container>
                        <div className='border-top-dark py-3 py-sm-5 text-center'>
                            <Button className='button-gray text-dark py-3 px-4 ff-inter-semi text-uppercase' onClick={() => history.push('/research')}>Explore More Research</Button>
                        </div>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default PPM