import Footer from "components/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useEffect } from "react";
import './index.css'
import {
    Col,
    Row,
} from "reactstrap";
import Analysis from "../Home/Analysis";
import { AnchorScroll } from 'helper';
import { Helmet } from "react-helmet";

const About = () => {
    useEffect(() => {
        AnchorScroll()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | About</title>
                <meta name="title" content="PRIYA | About" />
                <meta name="description" content="We're a team of AI researchers and developers building next generation AI models to improve society and understand reality." />
            </Helmet>
            <div className="bg-dark">
                <div className="bg-banner">
                    <section className="py-0 nav-section">
                        <IndexNavbar className="fixed-top" />
                    </section>
                    <section className='my-sm-5'>
                        <Row className='align-items-center'>
                            <Col md="7">
                                <h2 className="text-uppercase font-banner-research mb-0 ff-light text-banner mr-sm-4 mt-2">OUR MISSION IS TO ACCELERATE ECONOMIC GROWTH WITH SOPHISTICATED AI</h2>
                            </Col>
                            <Col md="5">
                                <p className="text-gray font-style ff-light text-24 mt-4 mt-md-0">We're a team of AI researchers and developers building next generation AI models to improve society and understand reality.</p>
                            </Col>
                        </Row>
                    </section>
                </div>
                <div className="bg-dark">
                    <section>
                        <Row>
                            <Col md="4" className="text-center">
                                <p className="text-white ff-light work-head">4+ YEARS</p>
                                <p className="ff-light">AI TRAINING &amp; RESEARCH</p>
                            </Col>
                            <Col md="4" className="text-center mt-4 mt-md-0">
                                <p className="text-white ff-light work-head">800M+</p>
                                <p className="ff-light">DATA POINTS TRAINED</p>
                            </Col>
                            <Col md="4" className="d-flex flex-column align-items-center mt-4 mt-md-0">
                                <div className="d-flex align-items-center">
                                    <p className="text-white mb-0 ff-light work-head">
                                        19%
                                    </p><span className="ml-3 ff-light text-white">(and growing)</span>
                                </div>
                                <p className="ff-light">AVG PRICE OPTIMIZATION</p>
                            </Col>
                        </Row>
                    </section>
                </div>
                <div className="bg-works">
                    <section>
                        <p className="ff-light text-banner font-gradient-text text-center text-uppercase mb-5">
                            GET IN TOUCH WITH US
                        </p>
                        <Row className="my-5">
                            <Col md="3" className="text-center">
                                <div>
                                    <span className="ff-light text-gray">MEDIA</span>
                                    <p className="ff-light text-18">media@priya.ai</p>
                                </div>
                            </Col>
                            <Col md="3" className="text-center mt-3 mt-md-0">
                                <div>
                                    <span className="ff-light text-gray">INVESTOR RELATIONS</span>
                                    <p className="ff-light text-18">invest@priya.ai</p>
                                </div>
                            </Col>
                            <Col md="3" className="text-center mt-3 mt-md-0">
                                <div>
                                    <span className="ff-light text-gray">SUPPORT</span>
                                    <p className="ff-light text-18">support@priya.ai</p>
                                </div>
                            </Col>
                            <Col md="3" className="text-center mt-3 mt-md-0">
                                <div>
                                    <span className="ff-light text-gray">RESEARCH DEPT</span>
                                    <p className="ff-light text-18">research@priya.ai</p>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
                <Analysis />
                <div className="bg-dark">
                    <div>
                        <section className="text-center">
                            <div className="careers-section">
                                <p className="ff-light text-banner font-gradient-text text-center">
                                    CAREERS
                                </p>
                                <p className="text-white mt-4 ff-light font-subhead">
                                    Join us as we accelerate the world's economy and <br />
                                    improve small businesses everywhere.
                                </p>
                                <p className="text-gray text-16 mt-5 ff-light">
                                    NEW OPENINGS COMING SOON.
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default About;
