import React from "react";
import { Col, Row } from "reactstrap";
import Config from "../../../configs/config.json";
import ReactPlayer from "react-player";
import video from "../../../assets/img/Priya_AI/GLOBE_LOOP.gif";
const Integration = () => {
  return (
    <>
      <section>
        <Row data-aos="zoom-in">
          <Col xl="8" xxl="6">
            <p className="ff-light text-banner font-gradient-text text-uppercase mt-4 mt-sm-0">
              easy integration process
            </p>
            <h2 className="text-white ff-light work-head mt-3">
              Integrate PRIYA with your store to start optimizing your revenue
              immediately.
            </h2>
            <div className="gap-2 d-flex justify-content-md-center justify-content-lg-start">
                                    <button className="btn-white btn-banner ff-bold text-dark mt-3 w-full py-3" onClick={() => window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/onboarding`}>TRY PRIYA</button>
                                    <a target="_blank"  href={`${process.env.REACT_APP_DASHBOARD_URL}/auth`} className="py-3  btn-banner mt-3 btn-transparent text-16 d-flex justify-content-center align-items-center text-white ff-bold" >BOOK A DEMO</a>
                                    </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col md="7">
            <Row className="mt-5 text-center text-sm-left">
              <Col sm="6">
                <h2 className="text-uppercase font-banner mb-0 ff-light text-banner ">
                  250+
                </h2>
                <p className="text-gray font-style ff-book mb-0">
                  Brands using PRIYA (and growing)
                </p>
              </Col>
              <Col sm="6" className="mt-4 mt-sm-0">
                <h2 className="text-uppercase font-banner mb-0 ff-light text-banner ">
                  800M
                </h2>
                <p className="text-gray font-style ff-book mb-0">
                  Data Points Trained
                </p>
              </Col>
            </Row>
            <Row className="mt-4 mt-sm-5 text-center text-sm-left">
              <Col sm="6">
                <h2 className="text-uppercase font-banner mb-0 ff-light text-banner ">
                  $3.5M+
                </h2>
                <p className="text-gray font-style ff-book mb-0">
                  Addl Revenue Generated
                </p>
              </Col>
              <Col sm="6" className="my-4 mt-sm-0">
                <h2 className="text-uppercase font-banner mb-0 ff-light text-banner ">
                  19%
                </h2>
                <p className="text-gray font-style ff-book mb-0">
                  Avg Price Optimization
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="5" className="text-center text-lg-left position-rel">
            <img src={video} className="img-fluid" />
            {/* <img src={require('../../../assets/img/Priya_AI/globe.png').default} className="img-fluid" />
                        <img src={require('../../../assets/img/Priya_AI/line.png').default} className="img-fluid rotate" /> */}
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Integration;
