// import SignupModal from 'components/SignupModal'
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Config from "../../../configs/config.json";
import video from "../../../assets/img/Priya_AI/Rev_5.gif";
import HowItWorks1 from "../../../assets/img/how-it-works-1.png";
import HowItWorks2 from "../../../assets/img/how-it-works-2.png";
import HowItWorks3 from "../../../assets/img/how-it-works-3.png";
import HowItWorks4 from "../../../assets/img/how-it-works-4.png";
import Check from "../../../assets/img/check.png";
import Check2 from "../../../assets/img/check2.png";
import DashboardImage2 from "../../../assets/img/dashboard4.png";
import DashboardImage3 from "../../../assets/img/dashboard5.png";
import WithPriya from "../../../assets/img/with.png";
import WithOutPriya from "../../../assets/img/without.png";

import "./work.css";
import ButtonCarousel from "components/ButtonCarosal";
import PrimaryButton from "components/ui/PrimaryButton";
import SecondaryButton from "components/ui/SecondaryButton";

const About = () => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const content = [
    {
      title: "Run Price Simulations",
      description:
        "Enter any price for any of your products to simulate approximately how your revenue and orders will be impacted over time.",
      points: [
        "Simulate projected revenues, sales volume, and conversion rates.",
        "Compare results with your current prices and any new price you enter.",
        "Use AI to run predictive analysis up to a year into the future.",
      ],
      image: <img
        src={HowItWorks1}
        alt="Floating Image"
        className="dashboard-image"
      />
    },
    {
      title: "Analyze Competition",
      description:
        "Analyze the top competitors for each of your products, and how they stack up vs you in the market",
      points: [
        "View AI-powered data on your top competitors’ products.",
        "Side-by-side comparison of your prices with your competitor’s prices.",
        "Up-to-date pricing data on your competitors so you don’t fall behind.",
      ],
      image: <img
        src={HowItWorks2}
        alt="Floating Image"
        className="dashboard-image"
      />
    },
    {
      title: "Product Performance",
      description:
        "Drill down to the core metrics for each of your products to see how they’re performing.",
      points: [
        "View sales activity, order volume, and more data for each product.",
        "View pricing data on every product, and how it can be improved.",
        "Understand the price history and associated data for each product.",
      ],
      image: <img
        src={HowItWorks3}
        alt="Floating Image"
        className="dashboard-image"
      />
    },
    {
      title: "Enable Autopilot Pricing™",
      description:
        "Allow PRIYA AI to autonomously price your products for maximum revenue and efficiency.",
      points: [
        "PRIYA AI analyzes competitors, market data, and sales activity to update prices accordingly.",
        "Leverage the power of AI to analyze millions of data-points for the most optimal pricing.",
        "Automatically price-test any of your products to find the most optimal price, without the manual work.",
      ],
      image: <img
        src={HowItWorks4}
        alt="Floating Image"
        className="dashboard-image"
      />
    },
  ];

  return (
    <>
      <div className="bg-white how-it-work" >
        <div className="how-it-works-container">
          <p className="how-it-works-title">HOW IT WORKS</p>
          <p className="how-it-works-description">
            Detailed analytics on how your prices affect (or could affect) your
            revenue
          </p>
        </div>
        <ButtonCarousel
          setActiveButtonIndex={setActiveButtonIndex}
          activeButtonIndex={activeButtonIndex}
        />

        <Row className="py-5 px-4" id='workContent'>
          <Col className="workBtnsDiv" id="workBtnsDiv">
            <div className="image-container">
              <div className="image-wrapper">
                {content[activeButtonIndex]?.image}
              </div>
            </div>
            <div className="gap-2 d-flex justify-content-start work-btn-div " id="mobile-work-ps-button">
              <PrimaryButton id="desktop-hero-primary-btn"  backgroundColor='black' color='white' title='START FOR FREE' />
              <PrimaryButton id="mobile-hero-primary-btn"  backgroundColor='black' color='white' className="secondary-btn-div" title='START FOR FREE' />
              <SecondaryButton  borderColor='black' color='black' title='BOOK A DEMO' className="" />
            </div>
          </Col>
          <Col className="points-main-div">
            {/* <div className="text-container"> */}
            <div className="points-main-div-first">
              <h2 className="description-heading">
                {content[activeButtonIndex]?.description}
              </h2>
              <ul className="points-list">
                {content[activeButtonIndex]?.points?.map((point, idx) => (
                  <li className="list-item" key={idx}>
                    <img
                      src={Check}
                      alt="Check icon"
                      className="check-icon"
                    />
                    {point}
                  </li>
                ))}
              </ul>
            </div>
            <div className="gap-2 d-flex justify-content-start work-btn-div" id="desktop-work-ps-button">
              <PrimaryButton backgroundColor='black' color='white' title='START FOR FREE' />
              <SecondaryButton borderColor='black' color='black' title='BOOK A DEMO' />
            </div>
          </Col>
        </Row>

        <div className="grid-container"></div>
      </div>

      <section className="case-section" id="">
        <div className="px-4" id="caseStudySection">
          <Row className="flex-column-reverse flex-lg-row align-items-center">
            <Col lg="6" className="text-start" id="case-section-content">
              <div className="w-100">
                <h2 className="text-uppercase mb-0  text-banner d-none d-lg-block mb-5 " id="case-studies-title">
                  CASE STUDIES
                </h2>
                <h2 className="text-white mb-2" style={{ fontSize: "36px" }} id="modishladyText" >
                  modishlady.com
                </h2>
                <h2 className="text-muted mb-2" style={{ fontSize: "24px", }} id="modishladyBottomText">
                  increased revenue by <span className="text-white">28%</span>{" "}
                  using PRIYA
                </h2>
                <ul className="list-unstyled mt-4 text-muted">
                  <li className="case-study-points d-flex align-items-start my-3">
                    <img
                      src={Check2}
                      alt="Check"
                      width={24}
                      height={24}
                      className="me-2"
                      style={{ marginInlineEnd: '10px' }}
                    />
                    Using PRIYA AI to simulate price changes for 85+ products
                    which resulted in recovering money left on the table.
                  </li>
                  <li className="case-study-points d-flex align-items-start my-3">
                    <img
                      src={Check2}
                      alt="Check"
                      width={24}
                      height={24}
                      className="me-2"
                      style={{ marginInlineEnd: '10px' }}
                    />
                    Enabled Autopilot Pricing™ to switch from static prices to
                    dynamic prices using AI.
                  </li>
                  <li className="case-study-points d-flex align-items-start my-3">
                    <img
                      src={Check2}
                      alt="Check"
                      width={24}
                      height={24}
                      className="me-2"
                      style={{ marginInlineEnd: '10px' }}
                    />
                    Increased overall revenue, order value, and conversion rates
                    by using intelligent pricing.
                  </li>
                </ul>
                <div className="gap-2 d-flex justify-content-start work-btn-div">
                  <PrimaryButton title='TRY IT WITH MY STORE' />
                </div>
              </div>
            </Col>
            <Col lg="6" className="text-center">
              <h2
                style={{
                  backgroundColor: "orange",
                  textAlign: "start",
                  fontSize: 16,
                }}
                className="text-uppercase  mb-0 ff-light text-banner  bg-clip-text d-lg-none mb-3" id="case-studies-title"
              >
                CASE STUDIES
              </h2>
              <img
                src={DashboardImage2}
                alt="Dashboard"
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className="case-section">
        <Row className="justify-content-center align-items-center">
          <Col lg="6" className="text-center">
            <img
              src={DashboardImage3}
              alt="Floating Image"
              className="img-fluid"
            />
          </Col>
          <Col lg="6" className="pt-4" id="case-section-content">
            <h2 className="text-white mb-2" style={{ fontSize: "36px" }} id="modishladyText">
              dorsalfins.com
            </h2>
            <h4 className="text-muted mb-2" style={{ fontSize: "24px" }} id="modishladyBottomText">
              Increased average order value by
              <span className="text-white"> 17%</span> using PRIYA
            </h4>
            <ul
              className="list-unstyled text-muted"
              style={{ fontSize: "16px" }}
            >
              <li className="d-flex align-items-center my-3 case-study-points ">
                <img
                  src={Check2}
                  alt="Check"
                  style={{ width: "24px", height: "24px", marginRight: "10px" }}
                />
                Found out they were leaving money on the table with their top
                selling SKUs.
              </li>
              <li className="d-flex align-items-center my-3 case-study-points ">
                <img
                  src={Check2}
                  alt="Check"
                  style={{ width: "24px", height: "24px", marginRight: "10px" }}
                />
                Used PRIYA to analyze how they stack up against their closest
                competitors.
              </li>
              <li className="d-flex align-items-center my-3 case-study-points ">
                <img
                  src={Check2}
                  alt="Check"
                  style={{ width: "24px", height: "24px", marginRight: "10px" }}
                />
                Optimized Autopilot Pricing™ for competitive pricing to gain a
                market edge.
              </li>
            </ul>
            <div className="gap-2 d-flex justify-content-start work-btn-div">
              <PrimaryButton title='TRY IT WITH MY STORE' />
            </div>
          </Col>
        </Row>
      </section>

      <div className="price-comparison-container">
        <div className="background-section">
          <div className="text-section">
            <p className="text-content-desc">
              Stop guessing if your prices are too high or too low
            </p>
          </div>
        </div>

        <div className="image-comparison-section">
          <div className="image-div" id="work-btm-img-div">
            <img
              src={WithOutPriya}
              alt="Without Priya"
              className="priyaImage"
              style={{ top: "10%" }}
            />
            <img src={WithPriya} alt="With Priya" className="priyaImage" />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
